<template>
  <div>
    <h1 class="text-2xl mb-6">Données du site audité</h1>
    <div v-if="noData">
      Merci de remplir le formulaire afin d'obtenir les données.
    </div>
    <v-card class="pt-7 pb-3 mb-3">
      <v-card-title class="text-h5 ml-3 mb-3 font-weight-bold">
        Site Positions
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchPos"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        class="elevation-1 ml-6 mr-6"
        :headers="headersPos"
        :items="itemsPos"
        :search="searchPos"
      ></v-data-table>
    </v-card>

    <v-card class="pt-7 pb-3 mb-3">
      <v-card-title class="text-h5 ml-3 mb-3 font-weight-bold">
        Site Pages
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchPages"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        class="elevation-1 ml-6 mr-6"
        :headers="headersPages"
        :items="itemsPages"
        :search="searchPages"
      ></v-data-table>
    </v-card>

    <v-card class="pt-7 pb-3 mb-3">
      <v-card-title class="text-h5 ml-3 mb-3 font-weight-bold">
        Site Canibalisation
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchCani"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        class="elevation-1 ml-6 mr-6"
        :headers="headersCani"
        :items="itemsCani"
        :search="searchCani"
      ></v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
export default {
  name: "display",
  data() {
    return {
      icons: {
        mdiMagnify,
      },
      searchPos: "",
      searchPages: "",
      searchCani: "",
      headersPos: [
        { text: "Keyword", value: "Keyword" },
        { text: "Position", value: "Position" },
        { text: "Search Volume", value: "Search Volume" },
        { text: "Traffic", value: "Traffic" },
        { text: "URL", value: "Url" },
        { text: "Catégorie", value: "Categorie" },
        { text: "Catégorie 2 ", value: "Catégorie 2" },
        { text: "Number of Results", value: "Number of Results" },
      ],
      headersPages: [
        { text: "URL", value: "Url" },
        { text: "Number of Keywords", value: "Number of Keywords" },
      ],
      headersCani: [
        { text: "Keyword", value: "Keyword" },
        { text: "Traffic", value: "Traffic" },
        { text: "URL Max", value: "URL max" },
        { text: "Nombre de pages", value: "Nombre de pages" },
      ],
    };
  },
  methods: {},
  computed: {
    noData() {
      if (!Array.isArray(this.getData.Site_positions)) {
        return true;
      } else {
        return false;
      }
    },
    getData() {
      return this.$store.getters["seo/getData"];
    },
    itemsPos() {
      return this.getData.Site_positions;
    },

    itemsPages() {
      return this.getData.Site_pages;
    },

    itemsCani() {
      return this.getData.Site_canibalisation;
    },
  },
};
</script>

<style scoped></style>
